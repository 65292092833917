import React, { Suspense, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import Liveness from '../pages/liveness/Liveness';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';

// lazy load all the views

// account
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));

//pages
const Dashboard = React.lazy(() => import('../pages/dashboard/Dashboard'));
const InterestRate = React.lazy(() => import('../pages/interest_rate/InterestRate'));
const CreditCardRateNew = React.lazy(() => import('../pages/products/CreditCardRateNew'));
const CreditCardRate = React.lazy(() => import('../pages/products/CreditCardRate'));
const PanelLoans = React.lazy(() => import('../pages/panel/PanelLoans'));
const PanelInsurance = React.lazy(() => import('../pages/panel/PanelInsurance'));
const PanelForesight = React.lazy(() => import('../pages/panel/PanelForesight'));
const ProposalsAll = React.lazy(() => import('../pages/loans/proposals/ProposalsAll'));
const ProposalsPhysical = React.lazy(() => import('../pages/loans/proposals/ProposalsPhysical'));
const ProposalsProduction = React.lazy(() => import('../pages/loans/proposals/ProposalsProduction'));
const ProposalNew = React.lazy(() => import('../pages/loans/proposals/ProposalNew'));
const CreditCard = React.lazy(() => import('../pages/loans/proposals/CreditCard'));
const CreditCardNew = React.lazy(() => import('../pages/loans/proposals/CreditCardNew'));
const Messages = React.lazy(() => import('../pages/messages/Messages'));
const MessagesNew = React.lazy(() => import('../pages/messages/MessageNew'));
const TableOfLoans = React.lazy(() => import('../pages/interest_rate/TableOfLoans'));
const Files = React.lazy(() => import('../pages/files/Files'));
const FileNew = React.lazy(() => import('../pages/files/FileNew'));
const Profile = React.lazy(() => import('../pages/account/Profile'));
const TermsOfUse = React.lazy(() => import('../pages/settings/termsofuse/TermsOfUse'));
const TermsOfUseNew = React.lazy(() => import('../pages/settings/termsofuse/TermsOfUseNew'));
const Policy = React.lazy(() => import('../pages/insurance/policy/Policy'));
const PolicyNew = React.lazy(() => import('../pages/insurance/policy/PolicyNew'));
const InsuranceInfos = React.lazy(() => import('../pages/insurance/infos/Infos'));
const InsuranceInfosNew = React.lazy(() => import('../pages/insurance/infos/InfoNew'));
const Certificates = React.lazy(() => import('../pages/insurance/certificate/Certificates'));
const Fonado = React.lazy(() => import('../pages/insurance/fonado/Fonado'));
const Company = React.lazy(() => import('../pages/settings/company/Company'));
const CompanyNew = React.lazy(() => import('../pages/settings/company/CompanyNew'));
const GaspUsers = React.lazy(() => import('../pages/settings/gaspusers/GaspUser'));
const GaspUsersNew = React.lazy(() => import('../pages/settings/gaspusers/GaspUserNew'));
const Products = React.lazy(() => import('../pages/products/Products'));
const ProductsNew = React.lazy(() => import('../pages/products/ProductsNew'));
const Partners = React.lazy(() => import('../pages/partners/Partners'));
const PartnerNew = React.lazy(() => import('../pages/partners/PartnerNew'));
const PartnersUsers = React.lazy(() => import('../pages/partners_users/PartnersUsers'));
const Clients = React.lazy(() => import('../pages/clients/Client'));
const Schedule = React.lazy(() => import('../pages/schedule/Schedule'));
const UserProfile = React.lazy(() => import('../pages/settings/gaspusers/Profiles'));
const ProfileUserNew = React.lazy(() => import('../pages/settings/gaspusers/ProfileUserNew'));

// sites
const SiteBanners = React.lazy(() => import('../pages/sites/banners/Banners'));
const SiteBannersNew = React.lazy(() => import('../pages/sites/banners/BannersNew'));
const SiteContacts = React.lazy(() => import('../pages/sites/contacts/Contacts'));
const SiteFiles = React.lazy(() => import('../pages/sites/files/Files'));
const SitePosts = React.lazy(() => import('../pages/sites/posts/Posts'));
const SitePostsNew = React.lazy(() => import('../pages/sites/posts/PostsNew'));
const SiteProperties = React.lazy(() => import('../pages/sites/properties/Properties'));
const SitesHoliday = React.lazy(() => import('../pages/sites/holiday/Holiday'));
const SitesHolidayNew = React.lazy(() => import('../pages/sites/holiday/HolidayNew'));

const loading = () => <div className=""></div>;

const LoadComponent = ({
  component: Component,
  searchOptions,
  setSearchOptions,
  confirmDeleteModal,
  setConfirmDeleteModal,
}) => (
  <Suspense fallback={loading()}>
    <Component
      searchOptions={searchOptions}
      setSearchOptions={setSearchOptions}
      confirmDeleteModal={confirmDeleteModal}
      setConfirmDeleteModal={setConfirmDeleteModal}
    />
  </Suspense>
);

const AllRoutes = () => {
  const [searchOptions, setSearchOptions] = useState({
    executeSearch: false,
    showSearch: true,
    showButton: true,
    screen: '',
    fields: {
      search: {
        label: 'Pesquisar...',
        type: 'input',
        value: '',
      },
    },
  });
  const [confirmDeleteModal, setConfirmDeleteModal] = useState({
    show: false,
    deleteMethod: null,
    refreshMethod: null,
  });

  let Layout = VerticalLayout;

  return useRoutes([
    // public routes
    { path: '/', element: <Root /> },
    { path: '/liveness/:livenessId', element: <Liveness /> },
    {
      path: '/',
      element: <DefaultLayout />,
      children: [
        {
          path: 'account',
          children: [
            { path: 'login', element: <LoadComponent component={Login} /> },
            { path: 'confirm', element: <LoadComponent component={Confirm} /> },
            { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
            { path: 'logout', element: <LoadComponent component={Logout} /> },
          ],
        },
      ],
    },
    {
      // auth protected routes
      path: '/',
      element: (
        <PrivateRoute
          component={Layout}
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
          confirmDeleteModal={confirmDeleteModal}
          setConfirmDeleteModal={setConfirmDeleteModal}
        />
      ),
      children: [
        {
          path: 'dashboard',
          element: (
            <LoadComponent
              component={Dashboard}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'interest_rate/:id',
          element: (
            <LoadComponent
              component={InterestRate}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'panel',
          children: [
            {
              path: 'panel-loans',
              element: (
                <LoadComponent
                  component={PanelLoans}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'panel-foresight',
              element: (
                <LoadComponent
                  component={PanelForesight}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'panel-insurance',
              element: (
                <LoadComponent
                  component={PanelInsurance}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
          ],
        },
        {
          path: 'loans',
          children: [
            {
              path: 'proposals',
              children: [
                {
                  path: 'generalcontrol',
                  element: (
                    <LoadComponent
                      component={ProposalsAll}
                      searchOptions={searchOptions}
                      setSearchOptions={setSearchOptions}
                      confirmDeleteModal={confirmDeleteModal}
                      setConfirmDeleteModal={setConfirmDeleteModal}
                    />
                  ),
                },
                {
                  path: 'physical',
                  element: (
                    <LoadComponent
                      component={ProposalsPhysical}
                      searchOptions={searchOptions}
                      setSearchOptions={setSearchOptions}
                      confirmDeleteModal={confirmDeleteModal}
                      setConfirmDeleteModal={setConfirmDeleteModal}
                    />
                  ),
                },
                {
                  path: 'production',
                  element: (
                    <LoadComponent
                      component={ProposalsProduction}
                      searchOptions={searchOptions}
                      setSearchOptions={setSearchOptions}
                      confirmDeleteModal={confirmDeleteModal}
                      setConfirmDeleteModal={setConfirmDeleteModal}
                    />
                  ),
                },
                {
                  path: ':proposalType/:proposalId',
                  element: (
                    <LoadComponent
                      component={ProposalNew}
                      searchOptions={searchOptions}
                      setSearchOptions={setSearchOptions}
                      confirmDeleteModal={confirmDeleteModal}
                      setConfirmDeleteModal={setConfirmDeleteModal}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'credit_card',
          element: (
            <LoadComponent
              component={CreditCard}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'credit_card/new',
          element: (
            <LoadComponent
              component={CreditCardNew}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: '/credit_card/:id',
          element: (
            <LoadComponent
              component={CreditCardNew}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'files',
          element: (
            <LoadComponent
              component={Files}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'files/:fileId',
          element: (
            <LoadComponent
              component={FileNew}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'products',
          element: (
            <LoadComponent
              component={Products}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'products/:productId',
          element: (
            <LoadComponent
              component={ProductsNew}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'credit_card_rate',
          element: (
            <LoadComponent
              component={CreditCardRate}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'credit_card_rate/new',
          element: (
            <LoadComponent
              component={CreditCardRateNew}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'credit_card_rate/:id',
          element: (
            <LoadComponent
              component={CreditCardRateNew}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'insurance',
          children: [
            {
              path: 'policy',
              element: (
                <LoadComponent
                  component={Policy}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'policy/:policyId',
              element: (
                <LoadComponent
                  component={PolicyNew}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'infos',
              element: (
                <LoadComponent
                  component={InsuranceInfos}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'infos/:fileId',
              element: (
                <LoadComponent
                  component={InsuranceInfosNew}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'certificates',
              element: (
                <LoadComponent
                  component={Certificates}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'fonado',
              element: (
                <LoadComponent
                  component={Fonado}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
          ],
        },
        {
          path: 'partners',
          element: (
            <LoadComponent
              component={Partners}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'partners/:partnerId',
          element: (
            <LoadComponent
              component={PartnerNew}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'partners_users',
          element: (
            <LoadComponent
              component={PartnersUsers}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'messages',
          element: (
            <LoadComponent
              component={Messages}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'messages/:messageId',
          element: (
            <LoadComponent
              component={MessagesNew}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'interest_rate',
          element: (
            <LoadComponent
              component={TableOfLoans}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'settings',
          children: [
            {
              path: 'termsofuse',
              element: (
                <LoadComponent
                  component={TermsOfUse}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'termsofuse/:termsId',
              element: (
                <LoadComponent
                  component={TermsOfUseNew}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'company',
              element: (
                <LoadComponent
                  component={Company}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'company/:companyId',
              element: (
                <LoadComponent
                  component={CompanyNew}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'gaspusers',
              element: (
                <LoadComponent
                  component={GaspUsers}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'gaspusers/:gaspusersId',
              element: (
                <LoadComponent
                  component={GaspUsersNew}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'gaspusers/profiles',
              element: (
                <LoadComponent
                  component={UserProfile}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'gaspusers/profiles/:profilesId',
              element: (
                <LoadComponent
                  component={ProfileUserNew}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
          ],
        },
        {
          path: 'account',
          children: [
            {
              path: 'profile',
              element: (
                <LoadComponent
                  component={Profile}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
          ],
        },
        {
          path: 'clients',
          element: (
            <LoadComponent
              component={Clients}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
            />
          ),
        },
        {
          path: 'sites',
          children: [
            {
              path: 'banners',
              element: (
                <LoadComponent
                  component={SiteBanners}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'banners/:bannerId',
              element: (
                <LoadComponent
                  component={SiteBannersNew}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'contacts',
              element: (
                <LoadComponent
                  component={SiteContacts}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'files',
              element: (
                <LoadComponent
                  component={SiteFiles}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'posts',
              element: (
                <LoadComponent
                  component={SitePosts}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'posts/:postId',
              element: (
                <LoadComponent
                  component={SitePostsNew}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'properties',
              element: (
                <LoadComponent
                  component={SiteProperties}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'schedule',
              element: (
                <LoadComponent
                  component={Schedule}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'holiday',
              element: (
                <LoadComponent
                  component={SitesHoliday}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
            {
              path: 'holiday/:holidayId',
              element: (
                <LoadComponent
                  component={SitesHolidayNew}
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  confirmDeleteModal={confirmDeleteModal}
                  setConfirmDeleteModal={setConfirmDeleteModal}
                />
              ),
            },
          ],
        },
      ],
    },
  ]);
};

export { AllRoutes };
